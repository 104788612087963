import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import styles from './Bar.module.scss';

const EmptyBar = ({ isDirty, setIsEnd, delay }) => {
    const ref = useRef(null);

    const [loadingAnimation, setLoadingAnimation] = useState(
        styles.BarPrincipalAppear
    );

    useEffect(() => {
        if (isDirty) {
            ref.current.addEventListener('animationend', handleEndAnimation);
            setLoadingAnimation(styles.BarEmptyDisappear);
        } else {
            setLoadingAnimation(styles.BarPrincipalAppear);
        }

        return () =>
            ref.current.removeEventListener(
                'animationiteration',
                handleEndAnimation
            );
    }, [isDirty]);

    function handleEndAnimation() {
        setIsEnd(true);
    }

    return (
        <div className={styles.Bar} data-testid="EmptyBar">
            <div
                ref={ref}
                style={{ animationDelay: `${delay}s`, height: '100%' }}
                className={classnames(styles.EmptyBar, loadingAnimation)}>
                <div className={styles.Money}>
                    <span>£0</span>
                </div>
            </div>
            <p className={styles.YearText}>Year 1</p>
        </div>
    );
};

export default EmptyBar;

import React, { useRef } from 'react';
import classnames from 'classnames';
import styles from '../Bars.module.scss';

const LoadingBalls = ({ setIsEnd, isDirty }) => {
    const ballsRef = useRef(false);
    const loadingBalls = ['', '', ''];
    if (ballsRef.current) {
        ballsRef.current.addEventListener('animationiteration', function() {
            if (!isDirty) {
                setIsEnd(true);
            }
        });
    }

    return (
        <div
            ref={ballsRef}
            className={styles.LoadingBallsContainer}
            data-testid="LoadingBalls">
            {loadingBalls.map((_, i) => (
                <div key={i} className={styles.LoadingBallContainer}>
                    <div
                        style={{ animationDelay: `${i / 9}s` }}
                        className={classnames(
                            styles.LoadingBall,
                            styles.LoadingBallAnimation
                        )}></div>
                </div>
            ))}
        </div>
    );
};

export default LoadingBalls;

import React, { useState, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './CalculatorRCF.module.scss';
import { getCountryBuild } from '../../util/localization';
import Button from 'aphrodite-shared/ui/Button/Button';
import {
    sendCalculatorInteractionToGA,
    getRepayments
} from '../../util/loanCalculator';
import { formatMoney } from 'aphrodite-shared/util/helpers';
import { getLoanCalculatorInterestRates } from '../../util/localization';
import IwSlider from '../IwSlider/IwSlider';
import IwContext from 'aphrodite-shared/IwContext/IwContext';
import PhonePopup from '../../components/PhonePopup/PhonePopup';
import { MAX_LOAN_SIZE } from 'aphrodite-shared/util/iwocaConstants';
import { rawIntl } from 'aphrodite-shared/IwIntl/IwIntlProvider';
import { Amplitude, AmplitudeWebsite } from '@iwoca/frontend-tracking-library';
import { useProductName } from '../../../aphrodite-uk/src/components/IwModals/StepZeroModal/StepZeroModalHelpers';

const trackCalculatorCta = (ctaText, productName) => {
    const calculatorCtaEvent = AmplitudeWebsite.getStep0ModalEventWithProductTypeAndText(
        AmplitudeWebsite.OPEN_CALCULATOR_STEP_0_MODAL,
        ctaText,
        productName
    );
    Amplitude.track(calculatorCtaEvent);
};

const MAX_AMOUNT_LIGHTNING_LOAN = 15000;
const DEFAULT_AMOUNT_LIGHTNING_LOAN = 5000;

const MAX_LOAN_AMOUNT = MAX_LOAN_SIZE[rawIntl.locale];
const DEFAULT_LOAN_AMOUNT = rawIntl.locale === 'uk' ? 10000 : 6000;

const MAX_MONTHS = 24;
const DEFAULT_MONTHS = 6;

const getMaxBorrowAmount = ({
    maximumAmountOverride,
    isLightningLoan
} = {}) => {
    if (isLightningLoan) return MAX_AMOUNT_LIGHTNING_LOAN;
    if (maximumAmountOverride) return maximumAmountOverride;

    return MAX_LOAN_AMOUNT;
};

const getDefaultValue = ({ maximumAmountOverride, isLightningLoan } = {}) => {
    if (isLightningLoan) return DEFAULT_AMOUNT_LIGHTNING_LOAN;
    if (maximumAmountOverride) return maximumAmountOverride / 5;

    return DEFAULT_LOAN_AMOUNT;
};

const CalculatorRCF = ({
    equalRepaymentText,
    totalRepayments,
    borrowUpToText,
    interestText,
    borrowButtonText,
    forText,
    helpText,
    text,
    phoneNumber,
    maximumLoanAmount,
    minimumLoanAmount = 1000,
    monthlyInterestRate,
    buttonGaId,
    noButtons = false
}) => {
    const productName = useProductName();
    const isLightningLoan =
        typeof window !== 'undefined' &&
        window.location.pathname.includes('lightning-loan');

    const maxBorrowAmount = getMaxBorrowAmount({
        maximumAmountOverride: maximumLoanAmount,
        isLightningLoan
    });
    const defaultValue = getDefaultValue({
        maximumAmountOverride: maximumLoanAmount,
        isLightningLoan
    });

    const [amount, setAmount] = useState(defaultValue);
    const [months, setMonths] = useState(DEFAULT_MONTHS);
    const [buttonAmount, setButtonAmount] = useState(defaultValue);

    const interestRate = monthlyInterestRate
        ? monthlyInterestRate
        : getLoanCalculatorInterestRates();

    const { equalRepayment, totalRepayment } = getRepayments({
        amount,
        months,
        interestRate
    });

    const context = useContext(IwContext);

    function handleOpenStepZero() {
        context.openStepZero();
        context.setBorrowAmount(buttonAmount);
        trackCalculatorCta('', productName);
    }

    const afterAmountSliderChange = (value) => {
        sendCalculatorInteractionToGA('flexi-loan', 'slider', 'amount', value);
        setButtonAmount(value);
    };

    const afterDurationSliderChange = (value) => {
        sendCalculatorInteractionToGA(
            'flexi-loan',
            'slider',
            'duration',
            value
        );
    };

    return (
        <div className={styles.CalculatorRCF}>
            <div className={styles.header}>
                <span data-testid="calculatorRCF-header">
                    <FormattedMessage
                        id="message"
                        defaultMessage={equalRepaymentText}
                        values={{
                            months: months,
                            equalRepayment: (
                                <span
                                    className={`${styles.blue} ${styles.monthlyRepayment}`}>
                                    {equalRepayment}
                                </span>
                            )
                        }}
                    />
                </span>
            </div>
            <p className={styles.mw200} data-testid="calculatorRCF-subHeader">
                <FormattedMessage
                    id="totalRepayment"
                    defaultMessage={totalRepayments}
                    values={{ totalRepayment: formatMoney(totalRepayment) }}
                />
                <span className={styles.blue}>
                    <FormattedMessage
                        id="interest"
                        defaultMessage={interestText}
                        values={{ interest: interestRate }}
                    />
                </span>
            </p>

            <div className={styles.sliders}>
                <div className={styles.slider}>
                    <IwSlider
                        type="currency"
                        onChange={(amount) => setAmount(amount)}
                        onAfterChange={afterAmountSliderChange}
                        ease={maxBorrowAmount >= 50000}
                        min={minimumLoanAmount}
                        max={maxBorrowAmount}
                        defaultValue={defaultValue}
                        step={minimumLoanAmount}
                        color="blue"
                    />
                    <p>{borrowUpToText}</p>
                </div>
                <div className={styles.slider}>
                    <IwSlider
                        type="months"
                        onChange={(months) => setMonths(months)}
                        onAfterChange={afterDurationSliderChange}
                        min={1}
                        max={MAX_MONTHS}
                        defaultValue={DEFAULT_MONTHS}
                        step={1}
                        color="orange"
                    />
                    <p>{forText}</p>
                </div>
            </div>
            {!noButtons && (
                <div className={styles.actions}>
                    <Button onClick={handleOpenStepZero} dataGaId={buttonGaId}>
                        <FormattedMessage
                            id="buttonAmount"
                            defaultMessage={borrowButtonText}
                            values={{
                                amount: (
                                    <span className={styles.MoneyButton}>
                                        {formatMoney(buttonAmount)}
                                    </span>
                                )
                            }}
                        />
                    </Button>
                    {getCountryBuild() === 'uk' && (
                        <PhonePopup
                            helpText={helpText}
                            text={text}
                            phoneNumber={phoneNumber}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default CalculatorRCF;

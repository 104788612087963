export const ceilToValue = (value, targetValue) =>
    Math.ceil(value / targetValue) * targetValue;

export const floorToValue = (value, targetValue) =>
    Math.floor(value / targetValue) * targetValue;

export const getValueFromEasedPosition = ({
    position,
    easingPower,
    min,
    max
}) =>
    Math.pow(
        position * (max ** easingPower - min ** easingPower) +
            Math.pow(min, easingPower),
        1 / easingPower
    );

export const getEasedPositionFromValue = ({ value, easingPower, min, max }) =>
    parseFloat(
        (value ** easingPower - min ** easingPower) /
            (max ** easingPower - min ** easingPower)
    );

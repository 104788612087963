import React from 'react';
import styles from '../Inputs.module.scss';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const InputSlider = ({
    className,
    min,
    max,
    step,
    value,
    defaultValue,
    onChange,
    onAfterChange
}) => {
    return (
        <Slider
            className={className}
            trackStyle={{
                backgroundColor: 'white',
                height: '8px'
            }}
            railStyle={{
                height: '8px',
                backgroundColor: '#9EA7B3'
            }}
            handleStyle={{
                backgroundColor: '#184681',
                height: '32px',
                width: '32px',
                marginTop: '-13px',
                border: 'solid 4px white'
            }}
            value={value}
            step={step}
            defaultValue={defaultValue}
            min={min}
            max={max}
            onChange={onChange}
            onAfterChange={onAfterChange}
        />
    );
};

export default InputSlider;

import React from 'react';

const TelIcon = ({ className }) => (
    <svg
        className={className}
        width="17"
        height="30"
        viewBox="0 0 17 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.40616 0.632992C4.04628 0.483107 3.65913 0.548447 3.32695 0.722636C2.96211 0.994542 2.67751 1.29766 2.5498 1.65765C0.35863 8.0559 0.140431 13.8212 1.87599 18.5622C3.61387 23.3094 7.29653 26.9806 12.7588 29.2753C13.1317 29.3657 13.5401 29.3294 13.8512 29.1721C14.1833 28.9992 14.4977 28.7089 14.6607 28.2794C15.235 26.7774 15.8106 25.2733 16.3843 23.7723C16.5321 23.3468 16.4889 22.9239 16.3553 22.5637C16.2217 22.2035 15.985 21.8769 15.6233 21.7281C14.4315 21.231 13.0269 20.0653 11.8877 19.1451C11.7585 19.0401 11.5761 19.0229 11.4215 19.1013L8.19877 20.7451C6.18894 17.6522 5.51233 15.7662 5.02701 11.9017L8.22969 10.2685C8.39255 10.1849 8.51731 9.99754 8.54302 9.79795C8.77267 8.13056 8.94562 5.85345 9.60731 4.39023C9.74186 3.97479 9.5849 3.48368 9.43859 3.18961C9.2829 2.88245 9.03785 2.57645 8.66868 2.43367C7.24784 1.83344 5.827 1.23322 4.40616 0.632992Z"
            fill="#FF594B"
        />
    </svg>
);

export default TelIcon;

import React from 'react';
import styles from './Popup.module.scss';
import TelIconSVG from '../../../SVGComponents/TelIconSVG';

const Popup = ({ text, phoneNumber, phoneNumberGaId = '' }) => {
    return (
        <div className={styles.Popup}>
            <p>{text}</p>
            <div className={styles.Telephone}>
                <TelIconSVG />
                <a href="tel:+442037780274" data-ga-id={phoneNumberGaId}>
                    {phoneNumber}
                </a>
            </div>
            <div className={styles.arrow}></div>
        </div>
    );
};

export default Popup;

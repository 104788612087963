export const RLS_COPY = {
    WIDTHDRAWAL: '3% withdrawal fee',
    TOTAL_INTEREST: 'Total interest',
    TOTAL_REPAYMENT: 'Total repayment',
    INTEREST_DISCLAIMER: {
        strong: '14.88% representitive APR ',
        light: '(3% withdrawal fee + 12.45% yearly interest rate)'
    },
    copy: {
        title: 'Recovery Loan Calculator',
        description:
            'Use our Recovery Loan calculator to find out what your loan could look like.',
        howDoesItWork: {
            title: 'How does our Recovery Loan Calculator work?',
            list: {
                first: '1. Put in how much you’d like to borrow',
                second: '2. See how much your repayments over 5 years will be',
                third: '3. If you’re happy with that, click ‘apply’.'
            },
            description:
                'The representative rate shown accounts for the fixed 3% fee and the interest which varies based on circumstances.'
        }
    }
};

export const CBILS_COPY = {
    MONTHLY_PAYMENT: 'Monthly payment',
    TOTAL_PAYMENT: 'Total payment',
    TOTAL_INTEREST: 'Total interest'
};

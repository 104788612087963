import allColors from '../../scss/variables/_colors.module.scss';
import allDesignTokenColors from '../../../aphrodite-uk/utils/designTokens.json';
export default {
    colors: {
        coral: '#fb534a',
        snow: '#F2F2F2',
        mercury: '#E5E5E5',
        babyBlue: '#EEF3F7',
        swamp: '#1B2D37',
        royalBlue: '#246AC1',
        oldGrey: '#A6A6A6',
        dankBlue: '#3C647C',
        denim: '#102F56',
        denim10: '#08172B',
        denim15: '#0C2340',
        denim45: '#4D809E',
        denim65: '#699FE3',
        london: '#58A1FA',
        london75: '#ADC8D7',
        london90: '#DDE8EE',
        london95: '#BFD4E0',
        primary60: '#5391df',
        core65: '#FB5950',
        ...allColors,
        ...allDesignTokenColors.colors
    }
};

import React from 'react';
import classnames from 'classnames';
import styles from '../shapes.module.scss';
import settings from '../settings';

const Settings = {
    sizes: {
        sm: {
            width: '231',
            height: '196'
        },
        md: {
            height: '456',
            width: '411'
        },
        lg: {
            height: '649',
            width: '534'
        }
    },
    ...settings
};

const LongArch = ({
    className,
    color = 'coral',
    size = 'md',
    rotate = 'left'
}) => {
    return (
        <svg
            className={classnames(styles.shape, className)}
            width={Settings.sizes[size].width}
            height={Settings.sizes[size].height}
            viewBox="0 0 459 414"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 410.964C125.833 423.297 391.8 358.564 457 0.963867"
                stroke={Settings.colors[color]}
                strokeWidth="3"
                transform={
                    rotate === 'right'
                        ? `translate(${Settings.sizes[size].width}, 0) scale(-1, 1)`
                        : ''
                }
            />
        </svg>
    );
};

export default LongArch;

import React from 'react';
import classnames from 'classnames';
import styles from '../shapes.module.scss';
import settings from '../settings';

const Settings = {
    sizes: {
        sm: {
            height: '191',
            width: '199'
        },
        md: {
            height: '331',
            width: '344'
        },
        lg: {
            height: '400',
            width: '435'
        }
    },
    ...settings
};

const WedgedArc = ({ className, color = 'coral', size = 'md' }) => {
    return (
        <svg
            width={Settings.sizes[size].width}
            height={Settings.sizes[size].height}
            className={classnames(styles.shape, className)}
            viewBox="0 0 340 325"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M168.969 324.804L169.788 324.806C175.075 324.803 179.923 320.328 179.928 315.058C180.005 241.366 240.203 181.397 314.118 181.369C318.975 181.356 324.034 181.662 329.188 182.227C331.892 182.531 334.591 181.665 336.628 179.85C338.665 178.036 339.826 175.459 339.828 172.729L340 9.9548C340.007 4.87575 336.018 0.69397 330.936 0.418772C325.371 0.141761 319.776 -0.0048261 314.298 0.000121234C175.187 0.0510972 56.6791 90.2023 14.486 214.963C9.20932 228.644 5.29985 243.675 2.88104 260.189C0.989877 270.808 0.144495 292.17 0.144495 292.17C0.0743619 294.513 0.0296548 296.881 0.0106954 299.276C-0.0355425 304.742 0.0691722 310.327 0.304794 315.883C0.542242 320.957 4.70225 324.969 9.79142 325H168.956L168.969 324.804Z"
                fill={Settings.colors[color]}
                // transform={`translate(${Settings.sizes[size].width}, 0) scale(-1, 1)`}
            />
        </svg>
    );
};

export default WedgedArc;

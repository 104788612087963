import React, { useState, useRef, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { sendCalculatorInteractionToGA } from '../../../../util/loanCalculator';
import styles from '../Inputs.module.scss';

const MonthsField = ({ error, setRef, loanMonths, setLoanMonths }) => {
    const [isPopupVisible, setPopupVisible] = useState(false);

    const ref = useRef(null);
    useEffect(() => {
        if (ref.current) {
            setRef(ref);
        }
    }, [ref.current]);

    useEffect(() => {
        document.addEventListener('focusin', handleClick);
        return () => {
            document.removeEventListener('focusin', handleClick);
        };
    }, []);

    function handleClick(e) {
        if (!ref.current.contains(e.target)) {
            setPopupVisible(false);
            return;
        }
    }

    return (
        <div ref={ref} className={styles.MonthsInputGroup}>
            {isPopupVisible && <PopupInfo />}
            <NumberFormat
                id="MonthsField"
                name="MonthsField"
                className={styles.Months}
                value={loanMonths}
                onFocus={() => setPopupVisible(true)}
                onChange={(e) => {
                    setLoanMonths(e.target.value);
                }}
                onBlur={(e) => {
                    sendCalculatorInteractionToGA(
                        'cbils',
                        'typed-input',
                        'duration',
                        e.target.value
                    );
                }}
            />
            <span className={styles.MonthsSuffix}>months</span>
            {error && <span className={styles.Error}></span>}
        </div>
    );
};

const PopupInfo = () => {
    return (
        <div className={styles.AmountPopupWindow}>
            <p>Enter a duration between 0 and 36 months</p>
            <svg
                className={styles.DownArrow}
                width="16"
                height="8"
                viewBox="0 0 16 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.41421 6.58579C8.63316 7.36684 7.36683 7.36683 6.58579 6.58579L6.99382e-07 -1.39876e-06L16 0L9.41421 6.58579Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};

export default MonthsField;

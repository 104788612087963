import React from 'react';
import { Element } from 'react-scroll';
import { Amplitude, AmplitudeWebsite } from '@iwoca/frontend-tracking-library';
import styles from './WantToKnowMore.module.scss';
import IwAccordion from '../../../../components/IwAccordion/IwAccordion';
import ReactMarkdown from 'react-markdown';
import classnames from 'classnames';

const WantToKnowMore = ({
    faq,
    dataGaId,
    title,
    subtitle,
    className,
    isTransactionalPage = false
}) => {
    return (
        <Element
            name="WantToKnowMore"
            className={classnames(
                {
                    [styles.gridClass]: isTransactionalPage
                },
                className
            )}>
            <section className={classnames(styles.WantToKnowMore)}>
                {title && <h2>{title}</h2>}
                {subtitle && (
                    <div className={styles.sublineWrapper}>
                        <ReactMarkdown
                            source={subtitle}
                            className={styles.subtitle}
                        />
                    </div>
                )}
                <div className={styles.AccordionContainer}>
                    {faq.map(({ question, answer, color, longAnswer }) => {
                        return (
                            <IwAccordion
                                key={question}
                                title={question}
                                content={
                                    longAnswer ? longAnswer.longAnswer : answer
                                }
                                className={styles.Accordion}
                                lineClass={styles.lineClass}
                                color={color}
                                dataGaId={dataGaId}
                                onClickFn={() => {
                                    Amplitude.track(
                                        AmplitudeWebsite.getFaqClicked(question)
                                    );
                                }}
                            />
                        );
                    })}
                </div>
            </section>
        </Element>
    );
};

export default WantToKnowMore;

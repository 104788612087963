import React from 'react';
import classnames from 'classnames';
import styles from './CalculatorSection.module.scss';
import { animateScroll as scroll } from 'react-scroll';
import ReactMarkdown from 'react-markdown';
import CalculatorSectionShapes from './shapes/shapes';
import CalculatorRCF from 'aphrodite-shared/components/CalculatorRCF/CalculatorRCF';
import ArrowSVG from '../../../../SVGComponents/ArrowSVG';

const InterestRates = ({
    calculatorCopy,
    calcDescription,
    maximumLoanAmount,
    contactBubble,
    showArrow = true,
    overflowShapes = {
        WedgedArchShape: true,
        LongArchShape: true,
        SectionArchShape: true
    },
    className,
    applyButtonGaId = 'calculator__cta',
    noButtons = false
}) => {
    const { SectionArchShape } = overflowShapes;
    function onArrowClick() {
        const innerHeight =
            typeof window !== 'undefined' ? window.innerHeight : '600';
        scroll.scrollMore(innerHeight - 100); // try exclude title of section
    }

    return (
        <section
            className={classnames(
                styles.CalculatorSectionContainer,
                className
            )}>
            {/* {WedgedArchShape && <WedgedArch className={styles.WedgedArch} />} */}
            <CalculatorSectionShapes ArchSectionShape={SectionArchShape}>
                <div
                    className={classnames(styles.CalculatorSection, className)}>
                    <h2 className={styles.hideMobile}>
                        {calculatorCopy.title}
                    </h2>
                    <h2 className={styles.showMobile}>
                        {calculatorCopy.mobileTitle}
                    </h2>
                    <hr />
                    <p className={styles.intro}>{calculatorCopy.subtitle}</p>
                    {calcDescription && (
                        <p className={styles.intro}>{calcDescription}</p>
                    )}
                    <div className={styles.card}>
                        <CalculatorRCF
                            text={
                                calculatorCopy.contactBubble
                                    ? calculatorCopy.contactBubble.text
                                    : contactBubble
                                    ? contactBubble.text
                                    : null
                            }
                            equalRepaymentText={
                                calculatorCopy.equalRepaymentText
                            }
                            repayments={calculatorCopy.repaymentText}
                            helpText={calculatorCopy.helpText}
                            totalRepayments={calculatorCopy.totalRepaymentText}
                            interestText={calculatorCopy.interestText}
                            borrowUpToText={calculatorCopy.borrowUpToText}
                            forText={calculatorCopy.forText}
                            phoneNumber={
                                calculatorCopy.contactBubble
                                    ? calculatorCopy.contactBubble.phoneNumber
                                    : contactBubble
                                    ? contactBubble.phoneNumber
                                    : null
                            }
                            borrowButtonText={
                                calculatorCopy.ctaButton.buttonText
                            }
                            maximumLoanAmount={maximumLoanAmount}
                            buttonGaId={applyButtonGaId}
                            noButtons={noButtons}
                        />
                    </div>
                    <ReactMarkdown
                        source={calculatorCopy.footerText.footerText}
                        className={styles.content}
                    />
                </div>
                {showArrow && (
                    <ArrowSVG className={styles.arrow} onClick={onArrowClick} />
                )}
            </CalculatorSectionShapes>
        </section>
    );
};

export default InterestRates;

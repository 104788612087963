import React from 'react';
import classnames from 'classnames';
import styles from '../shapes.module.scss';
import settings from '../settings';

const Settings = {
    sizes: {
        md: {
            height: '116',
            width: '122'
        },
        lg: {
            height: '227',
            width: '240'
        }
    },
    ...settings
};

const PeopleArch = ({
    className,
    color = 'coral',
    size = 'md',
    strokeWidth = '3'
}) => {
    return (
        <svg
            width={Settings.sizes[size].width}
            height={Settings.sizes[size].height}
            className={classnames(styles.shape, className)}
            viewBox="0 0 122 116"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M122 2.00013C49.5275 2.00013 2 54.1983 2 115.739"
                stroke={Settings.colors[color]}
                stroke-width={strokeWidth}
                stroke-miterlimit="10"
            />
        </svg>
    );
};

export default PeopleArch;

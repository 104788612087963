import React from 'react';
import Button from '../../../Button/Button';
import { Amplitude, AmplitudeWebsite } from '@iwoca/frontend-tracking-library';
import { useProductName } from '../../../../../aphrodite-uk/src/components/IwModals/StepZeroModal/StepZeroModalHelpers';

const trackHeaderLandingCta = (ctaText, productName) => {
    const headerLandingEvent = AmplitudeWebsite.getStep0ModalEventWithProductTypeAndText(
        AmplitudeWebsite.OPEN_HEADER_STEP_0_MODAL,
        ctaText,
        productName
    );
    Amplitude.track(headerLandingEvent);
};

export const HeaderLandingButton = ({
    url,
    buttonText,
    buttonProps,
    descriptionText,
    className,
    buttonClass
}) => {
    const productName = useProductName();
    return (
        <>
            {descriptionText && <div>{descriptionText}</div>}
            <Button
                {...buttonProps}
                onClick={() => {
                    trackHeaderLandingCta(buttonText, productName);
                }}
                buttonClass={buttonClass}
                className={className}
                href={url}>
                {buttonText}
            </Button>
        </>
    );
};

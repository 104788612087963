import React from 'react';
import {
    BasicDoughnut,
    BasicCircle,
    LongArch,
    ArchWithSquare
} from '../../../../../SVGComponents/shapes';
import styles from './shapes.module.scss';

const CalculatorSectionShapes = ({ children, ArchSectionShape }) => {
    return (
        <div className={styles.shapeContainer}>
            {/* {ArchSectionShape && (
                <BasicArch
                    color="london"
                    rotate="right"
                    className={styles.BasicDenimArch}
                    size="lg"
                />
            )} */}
            <BasicCircle
                color="london75"
                className={styles.BasicLondonCircle}
                sm="sm"
            />
            <BasicDoughnut
                color="denim"
                className={styles.BasicDenimDoughnut}
                sm="sm"
            />
            {/* <BasicGrid className={styles.BasicGrid} /> */}
            <LongArch
                rotate="right"
                className={styles.LongLondonArch}
                color="london"
            />
            {children}
            <ArchWithSquare className={styles.archWithSquare} />
        </div>
    );
};

export default CalculatorSectionShapes;

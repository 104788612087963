import React from 'react';
import classNames from 'classnames';

import Button from '../Button/Button';
import {
    BasicSquare,
    ArchWithCircle,
    BasicGrid
} from '../../SVGComponents/shapes';
import styles from './CBILSCtaWidget.module.scss';

const CBILSCtaWidget = ({
    title,
    subtitle,
    primaryButton,
    secondaryButton,
    className
}) => {
    return (
        <div className={classNames(styles.WidgetContainer, className)}>
            <div className={styles.Widget}>
                <h2>{title}</h2>
                <p>{subtitle}</p>
                <div className={styles.ButtonContainer}>
                    <Button
                        href={primaryButton.url}
                        dataGaId={primaryButton.dataGaId}
                        buttonClass={styles.Button}
                        colour="primary">
                        {primaryButton.buttonText}
                    </Button>
                    <span className={styles.Spacer}></span>
                    {secondaryButton.buttonText && (
                        <Button
                            href={secondaryButton.url}
                            dataGaId={secondaryButton.dataGaId}
                            className={styles.Button}
                            colour="secondaryDark">
                            {secondaryButton.buttonText}
                        </Button>
                    )}
                </div>
                <BasicSquare className={styles.BasicSquare} color="london75" />
                <BasicGrid className={styles.BasicArch} />
            </div>
        </div>
    );
};

export default CBILSCtaWidget;

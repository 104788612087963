import React from 'react';
import styles from './Questions.module.scss';
import Button from 'aphrodite-shared/ui/Button/Button';
import ReactMarkdown from 'react-markdown/with-html';
import TelIcon from '../../../../SVGComponents/TelIconSVG';
import EmailIcon from '../../../../components/Footer/svg/EmailIcon';
import { formatPhoneNumber } from '../../../../util/localization';
import CustomerSupport from './png/CustomerSupport.png';
import { fetchIsLoggedIn } from '../../../../util/authHelpers';
import { Amplitude, AmplitudeWebsite } from '@iwoca/frontend-tracking-library';
import { useProductName } from '../../../../components/IwModals/StepZeroModal/StepZeroModalHelpers';

const trackQuestionsCta = (ctaText, productName) => {
    const questionsEvent = AmplitudeWebsite.getStep0ModalEventWithProductTypeAndText(
        AmplitudeWebsite.OPEN_QUESTIONS_STEP_0_MODAL,
        ctaText,
        productName
    );
    Amplitude.track(questionsEvent);
};

const Questions = ({
    title,
    content,
    email,
    phoneNumber,
    applyButtonGaId = 'questions__cta',
    showApplyButton,
}) => {
    const loggedIn = fetchIsLoggedIn();
    const productName = useProductName();

    return (
        <section>
            <div className={styles.Questions}>
                <div className={styles.imageContainer}>
                    <img src={CustomerSupport} alt="Customer support" />
                </div>
                <div className={styles.content}>
                    <h2>Questions?</h2>
                    <h3>{title}</h3>
                    <ReactMarkdown source={content} escapeHtml={false} />
                    {loggedIn && showApplyButton && (
                        <Button
                            buttonClass={styles.apply}
                            href={'/my_account/'}
                            dataGaId={applyButtonGaId}
                            colour="primary">
                            Apply now
                        </Button>
                    )}
                    {!loggedIn && showApplyButton && (
                        <Button
                            onClick={() =>
                                trackQuestionsCta('Apply Now', productName)
                            }
                            buttonClass={styles.apply}
                            to={'#compare'}
                            dataGaId={applyButtonGaId}
                            colour="primary">
                            Apply now
                        </Button>
                    )}
                    <div className={styles.contact}>
                        <div className={styles.tel}>
                            <TelIcon />
                            <a
                                href={formatPhoneNumber(phoneNumber)}
                                data-ga-id="questions__phone">
                                {phoneNumber}
                            </a>
                        </div>
                        <div className={styles.email}>
                            <EmailIcon />
                            <a
                                href={`mailto:${email}`}
                                data-ga-id="questions__email">
                                {email}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Questions;

import { defineMessages } from 'react-intl';

export default defineMessages({
    month: {
        id: '_.iw_slider__month',
        defaultMessage: 'month'
    },
    months: {
        id: '_.iw_slider__months',
        defaultMessage: 'months'
    }
});

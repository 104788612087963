import React from 'react';
import styles from '../shapes.module.scss';
import classnames from 'classnames';
import settings from '../settings';

const Settings = {
    sizes: {
        xs: {
            width: '47',
            height: '47'
        },
        sm: {
            width: '260',
            height: '260'
        },
        md: {
            width: '335',
            height: '335'
        },
        lg: {
            height: '510',
            width: '510'
        }
    },
    ...settings
};

const BasicCircle = ({ className, color = 'coral', size = 'md' }) => {
    return (
        <svg
            className={classnames(styles.shape, className)}
            width={Settings.sizes[size].width}
            height={Settings.sizes[size].height}
            viewBox="0 0 335 335"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.04934 221.671C-20.868 134.159 25.8207 38.9671 113.332 9.04942C200.843 -20.8682 296.034 25.8211 325.951 113.333C355.868 200.841 309.176 296.037 221.672 325.951C134.161 355.868 38.9667 309.179 9.04934 221.671Z"
                fill={Settings.colors[color]}
            />
        </svg>
    );
};

export default BasicCircle;

import React, { useRef, useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { sendCalculatorInteractionToGA } from '../../../../util/loanCalculator';
import styles from '../Inputs.module.scss';

const AmountsField = ({
    error,
    setRef,
    loanAmount,
    setLoanAmount,
    maxLoanAmount
}) => {
    const [isPopupVisible, setPopupVisible] = useState(false);

    const ref = useRef(null);
    useEffect(() => {
        if (ref.current) {
            setRef(ref);
        }
    }, [ref.current]);

    useEffect(() => {
        document.addEventListener('focusin', handleClick);
        return () => {
            document.removeEventListener('focusin', handleClick);
        };
    }, []);

    function handleClick(e) {
        if (!ref.current.contains(e.target)) {
            setPopupVisible(false);
            return;
        }
    }

    const handleFocus = (event) => event.target.select();

    return (
        <div ref={ref} className={styles.AmountInputGroup}>
            {isPopupVisible && <PopupInfo />}
            <span className={styles.AmountPrefix}>£</span>
            <NumberFormat
                id="AmountField"
                name="AmountField"
                data-testid="textbox"
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={0}
                className={styles.Amount}
                value={loanAmount}
                isAllowed={({ value }) => value <= maxLoanAmount}
                onFocus={(e) => {
                    handleFocus(e);
                    setPopupVisible(true);
                }}
                onValueChange={({ value }) => {
                    setLoanAmount({ value, originIsTextInput: true });
                }}
                onBlur={(e) => {
                    sendCalculatorInteractionToGA(
                        'cbils',
                        'typed-input',
                        'amount',
                        e.target.value
                    );
                }}
            />
            {error && <span className={styles.Error}></span>}
        </div>
    );
};

const PopupInfo = () => {
    return (
        <div className={styles.AmountPopupWindow}>
            <p>Enter an amount between £50,001 and £750,000</p>
            <svg
                className={styles.DownArrow}
                width="16"
                height="8"
                viewBox="0 0 16 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.41421 6.58579C8.63316 7.36684 7.36683 7.36683 6.58579 6.58579L6.99382e-07 -1.39876e-06L16 0L9.41421 6.58579Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};

export default AmountsField;

import React from 'react';
import styles from '../shapes.module.scss';
import classnames from 'classnames';
import settings from '../settings';

const Settings = {
    sizes: {
        xs: {
            width: '12',
            height: '12'
        },
        sm: {
            width: '16',
            height: '16'
        },
        md: {
            width: '44',
            height: '44'
        },
        lg: {
            width: '55',
            height: '55'
        }
    },
    ...settings
};

const BasicSquare = ({
    className,
    color = 'coral',
    size = 'md',
    rotate = 'right'
}) => {
    return (
        <svg
            className={classnames(styles.shape, className)}
            width={Settings.sizes[size].width}
            height={Settings.sizes[size].height}
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.80742 31.1187L11.3191 6.96574C11.5121 6.11685 12.3403 5.59596 13.1888 5.78934L37.2343 11.2879C38.0828 11.4813 38.6037 12.31 38.4106 13.1589L32.9192 37.2336C32.7261 38.0825 31.8952 38.604 31.0495 38.41L7.00458 32.9142C6.14875 32.7402 5.61436 31.9676 5.80742 31.1187Z"
                fill={Settings.colors[color]}
                transform={
                    rotate === 'left' ? `translate(44, 0) scale(-1, 1)` : ''
                }
            />
        </svg>
    );
};

export default BasicSquare;

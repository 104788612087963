import React from 'react';
import classnames from 'classnames';
import styles from '../shapes.module.scss';
import settings from '../settings';

const Settings = {
    sizes: {
        md: {
            height: '259',
            width: '324'
        }
    },
    ...settings
};

const BubbleArch = ({
    className,
    primaryColor = 'london',
    secondaryColor = 'coral',
    size = 'md'
}) => {
    return (
        <svg
            className={classnames(styles.shape, className)}
            width={Settings.sizes[size].width}
            height={Settings.sizes[size].height}
            viewBox="0 0 250 324"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M-70.6786 1.00177C-96.8455 171.287 17.2312 268.756 180.908 307.857"
                stroke={Settings.colors[primaryColor]}
                strokeWidth="2.95665"
                strokeMiterlimit="10"
            />
            <path
                d="M184.742 308.773C185.648 304.982 189.455 302.642 193.247 303.548C197.039 304.454 199.378 308.262 198.472 312.053C197.566 315.845 193.758 318.184 189.967 317.278C186.175 316.373 183.836 312.565 184.742 308.773Z"
                stroke={Settings.colors[secondaryColor]}
                strokeWidth="7.88325"
            />
        </svg>
    );
};

export default BubbleArch;

import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    BasicCircle,
    ArchWithCircle
} from 'aphrodite-shared/SVGComponents/shapes';
import Button from 'aphrodite-shared/ui/Button/Button';
import InputForm from './Inputs/InputsForm';
import Bars from './Bars/Bars';
import { formatMoney } from 'aphrodite-shared/util/helpers';
import classnames from 'classnames/bind';
import styles from './CBILSCalculator.module.scss';
import {
    getExampleRepaymentScheduleSummaries,
    getMonthSummary,
    DEFAULT_REPAYMENT_SCHEDULE_CBILS,
    DEFAULT_REPAYMENT_SCHEDULE_RLS
} from './API/API';
import { RLS_COPY, CBILS_COPY } from './constants';

const cx = classnames.bind(styles);

export const Calculator = ({ isRls = false }) => {
    const [formFields, setFormFields] = useState({
        loanAmount: isRls ? '500000' : '100000',
        loanMonths: isRls
            ? parseInt(DEFAULT_REPAYMENT_SCHEDULE_RLS.loan_duration_months)
            : parseInt(DEFAULT_REPAYMENT_SCHEDULE_CBILS.loan_duration_months)
    });

    const [repaymentSchedule, setRepaymentSchedule] = useState(() =>
        isRls
            ? DEFAULT_REPAYMENT_SCHEDULE_RLS
            : DEFAULT_REPAYMENT_SCHEDULE_CBILS
    );

    const getSummary = async () => {
        const { data: summaries } = await poolHandler(
            getExampleRepaymentScheduleSummaries(
                formFields.loanAmount,
                formFields.loanMonths
            )
        );

        const monthSummary = getMonthSummary(formFields.loanMonths, summaries);

        setRepaymentSchedule({ ...monthSummary, dirty: false }); // Dirty in this context means if a new value is being loaded so dirty = true = new value is being loaded and this one is old
    };

    useEffect(() => {
        setRepaymentSchedule({ ...repaymentSchedule, dirty: true });
        getSummary();
    }, [formFields.loanAmount, formFields.loanMonths]);

    return (
        <div className={cx(styles.CBILSCalculator, { RLSCalculator: isRls })}>
            <BasicCircle color="london95" className={styles.BasicCircle} />
            <div className={cx(styles.header, { rlsHeader: isRls })}>
                <div className={styles.headerForm}>
                    <InputForm
                        formFields={formFields}
                        setFormFields={setFormFields}
                        isRls={isRls}
                    />
                </div>
            </div>
            <div
                className={cx(styles.CalculatorBody, {
                    RLSCalculatorBody: isRls
                })}>
                <div className={cx(styles.results, { RLSResults: isRls })}>
                    <div className={styles.resultsData}>
                        <div
                            className={cx(styles.resultDetail, {
                                RLSResultDetail: isRls
                            })}>
                            <h4>
                                {isRls
                                    ? RLS_COPY.WIDTHDRAWAL
                                    : CBILS_COPY.MONTHLY_PAYMENT}
                            </h4>
                            {repaymentSchedule && !repaymentSchedule.dirty && (
                                <div
                                    className={classnames(
                                        styles.resultAmount,
                                        styles.TextAnimation,
                                        styles.withdrawalFee
                                    )}>
                                    {formatMoney(
                                        isRls
                                            ? repaymentSchedule
                                                  .repayment_schedule_summary
                                                  .total_funding_fee_repayment
                                            : repaymentSchedule
                                                  .repayment_schedule_summary
                                                  .monthly_repayment
                                    )}
                                </div>
                            )}
                        </div>
                        <div
                            className={cx(styles.resultDetail, {
                                RLSResultDetail: isRls
                            })}>
                            <h4>
                                {isRls
                                    ? RLS_COPY.TOTAL_INTEREST
                                    : CBILS_COPY.TOTAL_PAYMENT}
                            </h4>
                            {repaymentSchedule && !repaymentSchedule.dirty && (
                                <div
                                    className={classnames(
                                        styles.resultAmount,
                                        styles.TextAnimation,
                                        styles.totalInterest
                                    )}>
                                    {formatMoney(
                                        isRls
                                            ? repaymentSchedule
                                                  .repayment_schedule_summary
                                                  .total_interest_repayment
                                            : repaymentSchedule
                                                  .repayment_schedule_summary
                                                  .total_repayment
                                    )}
                                </div>
                            )}
                        </div>
                        <div
                            className={cx(styles.resultDetail, {
                                RLSResultDetail: isRls
                            })}>
                            <h4>
                                {isRls
                                    ? RLS_COPY.TOTAL_REPAYMENT
                                    : CBILS_COPY.TOTAL_INTEREST}
                            </h4>
                            {repaymentSchedule && !repaymentSchedule.dirty && (
                                <div
                                    className={classnames(
                                        styles.resultAmount,
                                        styles.TextAnimation
                                    )}>
                                    {formatMoney(
                                        isRls
                                            ? repaymentSchedule
                                                  .repayment_schedule_summary
                                                  .total_repayment
                                            : repaymentSchedule
                                                  .repayment_schedule_summary
                                                  .total_interest_repayment
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    {isRls && (
                        <div className={styles.interestDisclaimer}>
                            <span className={styles.disclaimerStrong}>
                                {RLS_COPY.INTEREST_DISCLAIMER.strong}
                            </span>
                            <span className={styles.disclaimerLight}>
                                {RLS_COPY.INTEREST_DISCLAIMER.light}
                            </span>
                        </div>
                    )}
                </div>
                <Bars
                    repaymentSchedule={repaymentSchedule}
                    loanAmount={formFields.loanAmount}
                    showFee={isRls}
                />
                <div
                    className={cx(styles.mobileResultsData, {
                        RLSMobileResultsData: isRls
                    })}>
                    <div
                        className={cx(styles.mobileResultDetail, {
                            RLSMobileResultDetail: isRls
                        })}>
                        <h4>
                            {isRls
                                ? RLS_COPY.WIDTHDRAWAL
                                : CBILS_COPY.MONTHLY_PAYMENT}
                        </h4>
                        {repaymentSchedule && !repaymentSchedule.dirty && (
                            <div
                                className={cx(
                                    styles.mobileResultAmount,
                                    styles.MobileTextAnimation,
                                    { withdrawalFee: isRls }
                                )}>
                                {formatMoney(
                                    isRls
                                        ? repaymentSchedule
                                              .repayment_schedule_summary
                                              .yearly_summary[0]
                                              .funding_fee_repayment
                                        : repaymentSchedule
                                              .repayment_schedule_summary
                                              .monthly_repayment
                                )}
                            </div>
                        )}
                    </div>
                    <div
                        className={cx(styles.mobileResultDetail, {
                            RLSMobileResultDetail: isRls
                        })}>
                        <h4>
                            {isRls
                                ? RLS_COPY.TOTAL_INTEREST
                                : CBILS_COPY.TOTAL_PAYMENT}
                        </h4>
                        {repaymentSchedule && !repaymentSchedule.dirty && (
                            <div
                                className={cx(
                                    styles.mobileResultAmount,
                                    styles.MobileTextAnimation,
                                    { totalInterest: isRls }
                                )}>
                                {formatMoney(
                                    isRls
                                        ? repaymentSchedule
                                              .repayment_schedule_summary
                                              .total_interest_repayment
                                        : repaymentSchedule
                                              .repayment_schedule_summary
                                              .total_repayment
                                )}
                            </div>
                        )}
                    </div>
                    <div
                        className={classnames(
                            cx(styles.mobileResultDetail, {
                                mobileResultTotal: !isRls,
                                RLSMobileResultTotal: isRls,
                                RLSMobileResultDetail: isRls
                            })
                        )}>
                        <h4>
                            {isRls
                                ? RLS_COPY.TOTAL_REPAYMENT
                                : CBILS_COPY.TOTAL_INTEREST}
                        </h4>
                        {repaymentSchedule && !repaymentSchedule.dirty && (
                            <div
                                className={classnames(
                                    styles.mobileResultAmount,
                                    styles.MobileTextAnimation
                                )}>
                                {formatMoney(
                                    isRls
                                        ? repaymentSchedule
                                              .repayment_schedule_summary
                                              .total_repayment
                                        : repaymentSchedule
                                              .repayment_schedule_summary
                                              .total_interest_repayment
                                )}
                            </div>
                        )}
                    </div>
                    {isRls && (
                        <div className={styles.rlsMobileInterestDisclaimer}>
                            <span className={styles.disclaimerStrong}>
                                {RLS_COPY.INTEREST_DISCLAIMER.strong}
                            </span>
                            <span className={styles.disclaimerLight}>
                                {RLS_COPY.INTEREST_DISCLAIMER.light}
                            </span>
                        </div>
                    )}
                </div>
                <div
                    className={cx(styles.ButtonsContainer, {
                        RLSButtonsContainer: isRls
                    })}>
                    {isRls && (
                        <div className={styles.monthlyRepaymentText}>
                            Monthly repayments of{' '}
                            <span className={styles.monthlyRepaymentAmount}>
                                {formatMoney(
                                    repaymentSchedule.repayment_schedule_summary
                                        .monthly_repayment
                                )}
                            </span>
                        </div>
                    )}
                    <Button
                        href="#apply"
                        colour="primary"
                        dataGaId="cbils_calculator_CTA_in_the_calculator_itself__apply">
                        <span>
                            Apply for {formatMoney(formFields.loanAmount)}
                        </span>
                    </Button>
                </div>
            </div>
            <ArchWithCircle
                primaryColor="coral"
                secondaryColor="london75"
                className={styles.ArchWithCircle}
            />
        </div>
    );
};

let poolQueue = [];
export const poolHandler = async (req) => {
    // expect async
    return new Promise((resolve) => {
        req.uuid = uuidv4();
        req.then((res) => {
            const currentReqIndex = poolQueue.findIndex(
                (poolRequest) => poolRequest.uuid === req.uuid
            );
            if (
                poolQueue[currentReqIndex] &&
                !poolQueue[currentReqIndex].cancel &&
                currentReqIndex === poolQueue.length - 1
            ) {
                // // the request wasn't cancelled OR the request that just resolved was the last request to have been made
                poolQueue.forEach((promise) => {
                    promise.cancel = true;
                });
                poolQueue = [];
                resolve(res);
            }
        });
        poolQueue.push(req);
    });
};

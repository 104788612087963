import React from 'react';
import ReactMarkdown from 'react-markdown';
import GatsbyImage from 'gatsby-image';
import { Location } from '@reach/router';
import { BasicDoughnut } from '../../../SVGComponents/shapes';
import TickSVG from 'aphrodite-shared/icons/TickSVG';
import classnames from 'classnames';
import styles from './HeaderLanding.module.scss';
import { HeaderLandingButton } from './HeaderLandingButton/HeaderLandingButton';
import DynamicTrustpilot from '../../DynamicTrustpilot/DynamicTrustpilot';

const HeaderLanding = ({
    title,
    subtitle,
    bulletPoints,
    mobileTitle,
    description,
    firstCTA,
    secondCTA,
    image,
    trustpilotRating,
    trustpilotReviews,
    additionalTrustElements,
    partnershipLogo
}) => {
    // If title is not defined make Subtitle larger
    const subtitleClass = title ? '' : styles.LargeSubtitle;
    return (
        <section>
            <BasicDoughnut
                color="mercury"
                className={styles.BasicMercuryDoughnut}
                sm="sm"
            />
            <div className={styles.Header}>
                {partnershipLogo && (
                    <>
                        <div className={styles.partnershipText}>
                            In partnership with
                        </div>
                        <GatsbyImage
                            className={styles.PartnershipLogo}
                            fluid={partnershipLogo.fluid}
                            imgStyle={{ objectFit: 'scale-down' }}
                        />
                    </>
                )}
                <div className={styles.info}>
                    {title && <h1 className={styles.Title}>{title}</h1>}
                    <h1 className={classnames(styles.Subtitle, subtitleClass)}>
                        {subtitle}
                    </h1>
                    <h1 className={styles.MobileTitle}>{mobileTitle}</h1>
                    {description && <ReactMarkdown source={description} />}
                    {bulletPoints && (
                        <div className={styles.BulletPoints}>
                            <ul>
                                {bulletPoints.map((bulletPoint, i) => {
                                    return (
                                        <li key={`bullet-point-${i}`}>
                                            <div className={styles.tick}>
                                                <TickSVG fill="#009967" />
                                            </div>
                                            <p>{bulletPoint.title}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    )}
                    <Location>
                        {({ location }) => {
                            switch (location.pathname) {
                                case '/recovery-loan-scheme/':
                                    return (
                                        <p>
                                            Signup and we'll let you know when
                                            new products are available
                                        </p>
                                    );
                                default:
                                    return null;
                            }
                        }}
                    </Location>
                    <div className={styles.ButtonContainer}>
                        {firstCTA && (
                            <HeaderLandingButton
                                buttonClass={styles.HeaderButton}
                                {...firstCTA}
                            />
                        )}
                        {secondCTA && (
                            <HeaderLandingButton
                                buttonClass={styles.HeaderButton}
                                className={styles.LastHeaderButton}
                                {...secondCTA}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.image}>
                    <GatsbyImage fluid={image.fluid} />
                </div>
            </div>
            <div>
                <div className={styles.trustElements}>
                    {trustpilotRating && trustpilotReviews && (
                        <DynamicTrustpilot className={styles.trustpilot} />
                    )}
                    {additionalTrustElements &&
                        additionalTrustElements.length > 0 &&
                        additionalTrustElements.map((trustElement) => {
                            const dataGaId =
                                trustElement.name === 'BBB'
                                    ? 'CBILS_lp_gov_site'
                                    : '';
                            return (
                                <a
                                    className={styles.TrustElementLink}
                                    data-ga-id={dataGaId}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={trustElement.url}>
                                    {' '}
                                    <GatsbyImage
                                        fluid={trustElement.logo.fluid}
                                        className={styles.TrustElement}
                                        key={trustElement.id}
                                    />
                                </a>
                            );
                        })}
                </div>
            </div>
        </section>
    );
};

export default HeaderLanding;

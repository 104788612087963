import { formatMoney } from 'aphrodite-shared/util/helpers';
import { postToDataLayer } from './googleTracking';

// http://financeformulas.net/Loan_Payment_Formula.html
export function calculateMonthlyEqualRepayment(
    presentValue,
    interest,
    numberOfPeriods
) {
    return (presentValue * interest) / (1 - (1 + interest) ** -numberOfPeriods);
}

export function getRepaymentDetails(requestedAmount, months, interestRate) {
    let repaymentDetails = [];

    const monthlyEqualRepayment = calculateMonthlyEqualRepayment(
        requestedAmount,
        interestRate / 100,
        months
    );

    for (let index = 0; index < months; index++) {
        const interest = (requestedAmount * interestRate) / 100;
        const originalLoan = monthlyEqualRepayment - interest;
        const monthlyRepayment = monthlyEqualRepayment;
        requestedAmount -= originalLoan;

        repaymentDetails.push({
            month: index + 1,
            originalLoan: formatMoney(originalLoan),
            interest: formatMoney(interest),
            monthlyRepayment: formatMoney(monthlyRepayment)
        });
    }

    return repaymentDetails;
}

export function getRepayments({ amount, months, interestRate }) {
    if (months === 0) return { totalRepayment: amount, equalRepayment: amount };

    const repaymentDetails = getRepaymentDetails(amount, months, interestRate);
    const equalRepayment = repaymentDetails[0].monthlyRepayment;
    const totalRepayment = Math.round(equalRepayment.props.value * months);

    return { totalRepayment, equalRepayment };
}

export function sendCalculatorInteractionToGA(type, element, field, value) {
    if (typeof window === 'undefined') return;
    const calculatorData = {
        event: 'calculatorInteraction',
        calculatorData: {
            type,
            element,
            field,
            value
        }
    };
    postToDataLayer(calculatorData);
}

import React, { useState, useEffect } from 'react';

import styles from './IwSlider.module.scss';
import messages from './IwSlider.messages';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { formatMoney } from 'aphrodite-shared/util/helpers';
import { useIntl } from 'react-intl';
import {
    ceilToValue,
    floorToValue,
    getValueFromEasedPosition,
    getEasedPositionFromValue
} from './IwSliderHelper';

const { createSliderWithTooltip } = Slider;
const SliderWithToolTip = createSliderWithTooltip(Slider);

const ROUND_TO_VALUE = 1000;
const EASING_POWER = 0.4;

const IwSlider = ({
    type,
    onChange,
    ease = false,
    onAfterChange = null,
    min,
    max,
    defaultValue,
    step = 500,
    color
}) => {
    /**
     * How easing works (getUneasedValue):
     * Value selected by user gets turned into value between 0 and 1
     * Takes value and uneases it which will give you another value between 0 and 1
     * Takes value and multiplies by the difference between min and max and adds min
     * Value gets passed to the onchange event as the value of the input
     *
     * Getting the default value works the opposite way
     */

    if (min % step !== 0 || max % step !== 0)
        throw new Error(
            `Min (${min}) and Max (${max}) values need to be divisible by step (${step})`
        );

    const roundedDefault = ceilToValue(defaultValue, ROUND_TO_VALUE);
    const handleColor = color === 'orange' ? '#FF594B' : '#1C5296';

    const [showTip, setShowTip] = useState(false);
    const { formatMessage } = useIntl();

    // fix top offset bug
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowTip(true);
        }, 600);
        return () => clearTimeout(timer);
    }, []);

    const renderTooltip = (value, type) => {
        value = ease ? getUneasedValue(value) : value;

        switch (type) {
            case 'currency':
                return formatMoney(value);
            case 'months':
                if (value === 1) {
                    return `${value} ${formatMessage({ ...messages.month })}`;
                }
                return `${value} ${formatMessage({ ...messages.months })}`;
            default:
                return value;
        }
    };

    const getUneasedValue = (easedValue) => {
        const position = parseFloat(
            ((easedValue - min) / (max - min)) * 100
        ).toFixed(1);

        const roundedValue = floorToValue(
            getValueFromEasedPosition({
                position: position / 100,
                easingPower: EASING_POWER,
                min,
                max
            }),
            ROUND_TO_VALUE
        );

        if (roundedValue <= min) return min;

        return roundedValue;
    };

    const getEasedValue = (inputValue) => {
        const targetPercentage = getEasedPositionFromValue({
            value: inputValue,
            easingPower: EASING_POWER,
            min,
            max
        });
        const value = targetPercentage * (max - min) + min;
        return ceilToValue(value, ROUND_TO_VALUE);
    };

    const onChangeHandler = (e) => {
        ease ? onChange(getUneasedValue(e)) : onChange(e);
    };

    const onAfterChangeHander = (e) => {
        if (!onAfterChange) return;
        ease ? onAfterChange(getUneasedValue(e)) : onAfterChange(e);
    };

    return (
        <SliderWithToolTip
            onChange={onChangeHandler}
            onAfterChange={onAfterChangeHander}
            min={min}
            max={max}
            defaultValue={ease ? getEasedValue(roundedDefault) : defaultValue}
            tipFormatter={(value) => (
                <>
                    <div
                        className={`${styles.tooltipArrow} ${
                            styles[`${color}Arrow`]
                        }`}
                    />
                    <div
                        data-testid="tooltip"
                        className={`${styles.tooltip} ${styles[color]}`}>
                        {renderTooltip(value, type)}
                    </div>
                </>
            )}
            tipProps={{
                visible: showTip
            }}
            handleStyle={{
                height: 32,
                width: 32,
                marginLeft: -16,
                marginTop: -14,
                backgroundColor: handleColor,
                border: '5px solid white',
                boxShadow: '0 2px 3px 0 rgba(23,23,23,0.88)'
            }}
            trackStyle={{
                height: '8px',
                backgroundColor: handleColor
            }}
            railStyle={{
                height: '8px',
                background: '#e0e0e0'
            }}
        />
    );
};

export default IwSlider;

import React from 'react';
import Img from 'gatsby-image';
import {
    BasicArch,
    BasicCircle,
    BasicDoughnut,
    BasicSquare
} from 'aphrodite-shared/SVGComponents/shapes';
import styles from './InfoAndImage.module.scss';
import ReactMarkdown from 'react-markdown/with-html';

const InfoAndImage = ({ title, textBlock, primaryImg }) => {
    return (
        <div className={styles.NewInfoAndImage}>
            <div className={styles.LeftPane}>
                <div className={styles.Image}>
                    <BasicSquare
                        className={styles.BasicSquare}
                        size="sm"
                        color="denim65"
                    />
                    <BasicArch className={styles.BasicArch} />
                    <BasicCircle
                        className={styles.BasicCircle}
                        color="denim150"
                    />
                    <BasicCircle
                        className={styles.SmallBasicCircle}
                        color="denim65"
                    />
                    <p>
                        We've been supporting over <span>50,000</span> small
                        businesses with access to finance since{' '}
                        <span>2012</span>.
                    </p>
                    <Img fluid={primaryImg.fluid} className={styles.coffee} />
                    <BasicDoughnut
                        className={styles.BasicDoughnut}
                        color="london95"
                    />
                </div>
            </div>
            <div className={styles.RightPane}>
                <div className={styles.TextContainer}>
                    <h2>{title}</h2>
                    <div className={styles.divider}></div>
                    <div className={styles.TextBlock}>
                        <ReactMarkdown source={textBlock} escapeHtml={false} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoAndImage;

import React, { useState, useEffect } from 'react';
import AmountsField from './AmountsField/AmountsField';
import MonthsField from './MonthsField/MonthsField';
import Slider from './Slider/Slider';
import { sendCalculatorInteractionToGA } from '../../../util/loanCalculator';
import styles from './Inputs.module.scss';

const maxLoanAmount = 750000;
const minLoanTerm = 1;
const maxLoanTerm = 60;

let timer;
const InputForm = ({ setFormFields, formFields, isRls = true }) => {
    const minLoanAmount = isRls ? 25000 : 50000;
    const minLoanAmountPlusOne = minLoanAmount + 1;
    const [loanMonths, setLoanMonths] = useState(formFields.loanMonths);
    const [monthsFieldRef, setMonthsRef] = useState(null);
    const [amountsFieldRef, setAmountsRef] = useState(null);
    const [inputErrors, setInputErrors] = useState({
        amounts: false,
        months: false
    });

    const [loanAmount, setLoanAmount] = useState({
        value: formFields.loanAmount,
        originIsTextInput: true
    });

    useEffect(() => {
        document.addEventListener('focusin', handleClick);
        document.addEventListener('keyup', handleTyping);
        return () => {
            document.removeEventListener('focusin', handleClick);
            document.removeEventListener('keyup', handleTyping);
        };
    }, [
        monthsFieldRef,
        amountsFieldRef,
        loanAmount.value,
        loanAmount.originIsTextInput,
        loanMonths,
        handleClick,
        handleTyping
    ]);

    function handleClick(e) {
        if (
            (monthsFieldRef && monthsFieldRef.current.contains(e.target)) ||
            amountsFieldRef.current.contains(e.target)
        ) {
            updateTimer();
        } else {
            const didError = errorHandler(
                inputErrors,
                setInputErrors,
                timer,
                loanAmount.value,
                loanMonths,
                minLoanAmountPlusOne
            );

            if (didError) return;

            setFormFields({
                loanAmount: loanAmount.value,
                loanMonths
            });
        }
    }

    function updateTimer() {
        if (timer) {
            clearInterval(timer);
        }

        const interval = setInterval(() => {
            const didError = errorHandler(
                inputErrors,
                setInputErrors,
                timer,
                loanAmount.value,
                loanMonths,
                minLoanAmountPlusOne
            );
            if (didError) return;

            setFormFields({
                loanAmount: loanAmount.value,
                loanMonths
            });
            clearInterval(timer);
        }, 1000);
        timer = interval;
    }

    function handleTyping(e) {
        if (
            e.keyCode === 13 &&
            ((monthsFieldRef && monthsFieldRef.current.contains(e.target)) ||
                amountsFieldRef.current.contains(e.target))
        ) {
            // If you're focused into amount field allow enter to trigger call
            const didError = errorHandler(
                inputErrors,
                setInputErrors,
                timer,
                loanAmount.value,
                loanMonths,
                minLoanAmountPlusOne
            );

            if (didError) return;

            setFormFields({
                loanAmount: loanAmount.value,
                loanMonths
            });
        } else {
            updateTimer();
        }
    }

    function handleLoanAmount(loanAmount) {
        if (loanAmount.originIsTextInput && loanAmount.value <= minLoanAmount) {
            return;
        } else if (loanAmount.value === minLoanAmount) {
            setLoanAmount({
                value: minLoanAmountPlusOne,
                originIsTextInput: false
            });
        } else {
            setLoanAmount({
                value: loanAmount.value,
                originIsTextInput: false
            });
        }
    }

    function handleLoanMonths(loanMonths) {
        if (loanMonths < minLoanTerm) {
            return;
        }
        setLoanMonths(loanMonths);
    }

    function onAmountSliderChange() {
        setLoanAmount({ ...loanAmount, originIsTextInput: false });
        setFormFields({
            loanAmount: loanAmount.value,
            loanMonths
        });
        sendCalculatorInteractionToGA(
            'cbils',
            'slider',
            'amount',
            loanAmount.value
        );
    }

    function onDurationSliderChange() {
        setLoanAmount({ ...loanAmount, originIsTextInput: false });
        setFormFields({
            loanAmount: loanAmount.value,
            loanMonths
        });
        sendCalculatorInteractionToGA(
            'cbils',
            'slider',
            'duration',
            loanMonths
        );
    }

    return (
        <>
            <div className={styles.AmountsField}>
                <label className={styles.BorrowText} for="AmountField">
                    I want to borrow
                </label>
                <AmountsField
                    error={inputErrors.amounts}
                    setRef={setAmountsRef}
                    loanAmount={loanAmount.value}
                    setLoanAmount={setLoanAmount}
                    initLoanAmount={loanAmount.value}
                    maxLoanAmount={maxLoanAmount}
                />
                <Slider
                    className={styles.DesktopAmountsFieldSlider}
                    step={1000}
                    defaultValue={100000}
                    value={loanAmount.value}
                    min={minLoanAmount}
                    max={maxLoanAmount}
                    onChange={(amount) =>
                        handleLoanAmount({
                            value: amount,
                            originIsTextInput: loanAmount.originIsTextInput
                        })
                    }
                    onAfterChange={onAmountSliderChange}
                />
            </div>
            <Slider
                className={styles.MobileAmountsFieldSlider}
                step={1000}
                defaultValue={100000}
                value={loanAmount.value}
                min={minLoanAmount}
                max={maxLoanAmount}
                onChange={(amount) =>
                    handleLoanAmount({
                        value: amount,
                        originIsTextInput: loanAmount.originIsTextInput
                    })
                }
                onAfterChange={onAmountSliderChange}
            />

            {!isRls && (
                <div className={styles.MonthsField}>
                    <label className={styles.ForText} for="MonthsField">
                        for
                    </label>
                    <MonthsField
                        error={inputErrors.months}
                        setRef={setMonthsRef}
                        loanMonths={loanMonths}
                        setLoanMonths={setLoanMonths}
                    />
                    <Slider
                        className={styles.DesktopMonthsFieldsSlider}
                        value={loanMonths}
                        trackStyle={{ backgroundColor: 'white' }}
                        defaultValue={maxLoanTerm}
                        min={minLoanTerm}
                        max={maxLoanTerm}
                        onChange={handleLoanMonths}
                        onAfterChange={onDurationSliderChange}
                    />
                </div>
            )}
            {!isRls && (
                <Slider
                    className={styles.MobileMonthsFieldsSlider}
                    defaultValue={maxLoanTerm}
                    value={loanMonths}
                    min={minLoanTerm}
                    max={maxLoanTerm}
                    onChange={handleLoanMonths}
                    onAfterChange={onDurationSliderChange}
                />
            )}
        </>
    );
};

function errorHandler(
    inputErrors,
    setInputErrors,
    timer,
    loanAmount,
    loanMonths,
    minLoanAmountPlusOne
) {
    let errors = inputErrors;
    if (loanAmount < minLoanAmountPlusOne || loanAmount > maxLoanAmount) {
        clearInterval(timer);
        setInputErrors({ ...errors, amounts: true });
        return true;
    } else {
        errors = { ...errors, amounts: false };
    }

    if (loanMonths < minLoanTerm || loanMonths > maxLoanTerm) {
        clearInterval(timer);
        setInputErrors({ ...errors, months: true });
        return true;
    } else {
        errors = { ...errors, months: false };
        setInputErrors(errors);
    }

    return false;
}

export default InputForm;

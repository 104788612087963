import React from 'react';
import classnames from 'classnames';
import styles from '../shapes.module.scss';
import settings from '../settings';

const Settings = {
    sizes: {
        xs: {
            width: '24',
            height: '24'
        },
        sm: {
            width: '262',
            height: '262'
        },
        md: {
            width: '351',
            height: '351'
        },
        lg: {
            width: '620',
            height: '620'
        },
        xlg: {
            width: '990',
            height: '990'
        }
    },
    ...settings
};

const BasicDoughnut = ({ className, color = 'coral', size = 'md' }) => {
    return (
        <svg
            className={classnames(styles.shape, className)}
            width={Settings.sizes[size].width}
            height={Settings.sizes[size].height}
            viewBox="0 0 288 289"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M81.221 274.074C152.478 308.703 238.256 278.841 272.764 207.335C307.272 135.829 277.514 49.7517 206.257 15.1229C135 -19.5059 49.2225 10.3559 14.7143 81.8621C-19.7939 153.368 9.96387 239.446 81.221 274.074ZM173.004 83.9308C206.436 100.121 220.329 140.506 204.196 174.056C188.062 207.605 147.817 221.547 114.385 205.356C80.9521 189.166 67.0592 148.781 83.1929 115.232C99.4162 81.6822 139.571 67.6508 173.004 83.9308Z"
                fill={Settings.colors[color]}
            />
        </svg>
    );
};

export default BasicDoughnut;

import React from 'react';
import lodashGet from 'lodash/get';
import { graphql } from 'gatsby';
import MetaData from '../../components/Layout/MetaData.jsx';
import { HeaderLanding } from 'aphrodite-shared';
import InfoAndImage from 'aphrodite-shared/ui/InfoAndImage/InfoAndImage';
import CBILSCtaWidget from 'aphrodite-shared/ui/CBILSCtaWidget/CBILSCtaWidget';
import WantToKnowMore from '../../templates/uk/common/WantToKnowMore/WantToKnowMore';
import Questions from '../../templates/uk/common/Questions/Questions';
import Footer from '../../components/Footer/Footer';
import { Calculator } from '../../components/CBILSCalculator/CBILSCalculator';
import { RLS_COPY } from '../../components/CBILSCalculator/constants';
import CalculatorSection from '../uk/common/CalculatorSection/CalculatorSection';
import NavBar from '../../components/NavBar/NavBar';
import styles from './CBILS.module.scss';

const CBILS = ({ data }) => {
    const {
        contentfulPageCbilsLanding: cbilsData,
        contentfulNewRepaymentCalculator: calcContent
    } = data;

    const {
        title,
        metaDescription,
        allowSearchEngineScraping,
        canonicalTag,
        url,
        showCalculator,
        productType
    } = cbilsData;

    const primaryButtonDataGaId =
        showCalculator === 'flexi-loan'
            ? 'flexiloan_lp__cta'
            : 'cbils_hero__apply';
    const secondaryButtonDataGaId = 'CBILS_lp_new_interest__apply';

    const faqSchema = setupSchema(cbilsData);

    const getFirstCTA = function() {
        if (lodashGet(cbilsData, 'heroBanner.firstCta')) {
            return {
                buttonProps: {
                    colour: 'primary',
                    dataGaId: primaryButtonDataGaId
                },
                ...cbilsData.heroBanner.firstCta
            };
        }
    };

    const getSecondCTA = function() {
        if (lodashGet(cbilsData, 'heroBanner.secondCta')) {
            return {
                buttonProps: {
                    colour: 'secondaryDark',
                    dataGaId: secondaryButtonDataGaId
                },
                ...cbilsData.heroBanner.secondCta
            };
        }
    };

    const isFlexiLoan = productType === 'Flexi-Loan';

    return (
        <div style={{ position: 'relative', overflowX: 'hidden' }}>
            <MetaData
                metaData={{
                    titleTag: title,
                    metaDescription,
                    allowSearchEngineScraping,
                    url,
                    canonicalTag,
                    structuredData: [faqSchema]
                }}
            />
            <NavBar displayCtaButton={false} />
            <HeaderLanding
                subtitle={cbilsData.heroBanner.subtitle}
                mobileTitle={cbilsData.heroBanner.mobileTitle}
                description={cbilsData.heroBanner.description.description}
                image={cbilsData.heroBanner.image}
                trustpilotRating={cbilsData.trustpilotRating.trustpilotRating}
                trustpilotReviews={cbilsData.trustpilotRating.numberOfReviews}
                trustpilot={cbilsData.trustpilotRating.copy}
                additionalTrustElements={cbilsData.trustedLogoList}
                bulletPoints={cbilsData.heroBanner.bulletPoints}
                firstCTA={getFirstCTA()}
                secondCTA={getSecondCTA()}
            />

            {isFlexiLoan && (
                <>
                    <InfoAndImage
                        primaryImg={cbilsData.infoSectionPrimaryImage}
                        title={cbilsData.infoSectionTitle}
                        textBlock={
                            cbilsData.infoSectionBlockText.infoSectionBlockText
                        }
                    />
                    <WantToKnowMore
                        faq={cbilsData.faqSection.faqs}
                        title={cbilsData.faqSection.title}
                        dataGaId="CBILS_wantToKnowMore_question"
                        subtitle={cbilsData.faqSection.subtitle.subtitle}
                    />
                    <div className={styles.cbilsCalculator}>
                        {showCalculator === 'rls' ? (
                            <>
                                <div class={styles.RLSDescription}>
                                    <h3>{RLS_COPY.copy.title}</h3>
                                    <hr></hr>
                                    <div>
                                        <p>{RLS_COPY.copy.description}</p>
                                    </div>
                                </div>
                                <Calculator isRls={showCalculator === 'rls'} />
                            </>
                        ) : (
                            <CalculatorSection
                                calculatorCopy={calcContent}
                                calcDescription="Using our Flexi-Loan calculator is easy – use the first slider to choose an amount between £1,000 and £500,000, and then choose how many months you’d like to borrow it for. You’ll be able to see how much you’ll repay each month, and can apply if you decide it’s the right choice for your business."
                                contactBubble={{
                                    text: 'text',
                                    phoneNumber: 'phoneNumber'
                                }}
                                overflowShapes={{
                                    WedgedArchShape: false,
                                    LongArchShape: false,
                                    SectionArchShape: false
                                }}
                                applyButtonGaId="calculator__cta"
                            />
                        )}
                    </div>
                    {lodashGet(cbilsData, 'ctaWidget') ? (
                        <CBILSCtaWidget
                            className={styles.ctaWidget}
                            title={cbilsData.ctaWidget.title}
                            subtitle={cbilsData.ctaWidget.subtitle.subtitle}
                            primaryButton={{
                                ...cbilsData.ctaWidget.primaryButton
                            }}
                            secondaryButton={{
                                ...cbilsData.ctaWidget.secondaryButton
                            }}
                        />
                    ) : null}
                </>
            )}

            <Questions
                title={cbilsData.contactUsSection.title}
                content={cbilsData.contactUsSection.content.content}
                email={cbilsData.contactUsSection.email}
                phoneNumber={cbilsData.contactUsSection.phoneNumber}
                overflowShapes={{ BasicDoughnutShaoe: false }}
            />
            <Footer hideCta={true} />
        </div>
    );
};

const setupSchema = (cbilsData) => {
    let schemaArticle = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        name: cbilsData.name,
        datePublished: cbilsData.createdAt,
        dateModified: cbilsData.updatedAt,
        mainEntity: []
    };

    cbilsData.faqSection.faqs.map(({ question, answer, longAnswer }) => {
        schemaArticle.mainEntity.push({
            '@type': 'Question',
            name: question,
            acceptedAnswer: {
                '@type': 'Answer',
                text: answer || longAnswer.longAnswer
            }
        });
    });

    return schemaArticle;
};

export const query = graphql`
    query CbilsandingPageQuery($id: String!) {
        contentfulPageCbilsLanding(id: { eq: $id }) {
            name
            title
            url
            metaDescription
            canonicalTag
            allowSearchEngineScraping
            createdAt
            updatedAt
            productType
            heroBanner {
                ...HeroLanding
            }
            trustpilotRating {
                trustpilotRating
                numberOfReviews
                copy
            }
            trustedLogoList {
                name
                logo {
                    fluid(maxWidth: 600, quality: 80) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
                url
            }
            infoSectionPrimaryImage {
                fluid(maxWidth: 600, quality: 80) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
            infoSectionTitle
            infoSectionBlockText {
                infoSectionBlockText
            }
            showCalculator
            calculatorDescriptionPosition
            faqSection {
                ...faqSection
            }
            ctaWidget {
                title
                subtitle {
                    subtitle
                }
                primaryButton {
                    buttonText
                    url
                    dataGaId
                }
                secondaryButton {
                    buttonText
                    url
                    dataGaId
                }
            }
            contactUsSection {
                ...contactUsSection
            }
        }

        contentfulNewRepaymentCalculator(
            id: { eq: "e94b54e2-3e10-52aa-bb02-d403bef0799e" }
        ) {
            borrowUpToText
            title
            mobileTitle
            totalRepaymentText
            subtitle
            equalRepaymentText
            forText
            helpText
            repaymentText
            footerText {
                footerText
            }
            ctaButton {
                buttonText
            }
            interestText
        }
    }

    fragment HeroLanding on ContentfulHeroLandingPage {
        title
        subtitle
        mobileTitle
        bulletPoints {
            title
        }
        description {
            description
        }
        image {
            fluid(maxWidth: 688, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
            }
        }
        firstCta {
            buttonText
            url
        }
        secondCta {
            buttonText
            url
        }
    }
`;

export default CBILS;

import React from 'react';
import classnames from 'classnames';
import styles from '../shapes.module.scss';
import settings from '../settings';

const Settings = {
    sizes: {
        md: {
            height: '291',
            width: '246'
        }
    },
    ...settings
};

const ArchWithCircle = ({
    className,
    primaryColor = 'london',
    secondaryColor = 'coral',
    size = 'md'
}) => {
    return (
        <svg
            className={classnames(styles.shape, className)}
            width={Settings.sizes[size].width}
            height={Settings.sizes[size].height}
            viewBox="0 0 256 291"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.3774 280.576C153.231 268.728 247.489 141.48 247.489 0.231506"
                stroke={Settings.colors[primaryColor]}
                strokeWidth="3"
                strokeMiterlimit="10"
            />
            <circle
                cx="21.8833"
                cy="279.789"
                r="8.42075"
                transform="rotate(-180 21.8833 279.789)"
                stroke={Settings.colors[secondaryColor]}
                strokeWidth="6.7366"
            />
            <path
                d="M26.2622 278.779C21.2098 279.789 5.37876 281.81 0.999967 282.147"
                stroke={Settings.colors[primaryColor]}
                strokeWidth="3"
            />
        </svg>
    );
};

export default ArchWithCircle;

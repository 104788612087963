import React from 'react';
import styles from '../shapes.module.scss';
import classnames from 'classnames';
import settings from '../settings';

const Settings = {
    sizes: {
        md: {
            width: '405',
            height: '175'
        }
    },
    ...settings
};

const BasicGrid = ({ className, size = 'md' }) => {
    return (
        <svg
            width={Settings.sizes[size].width}
            height={Settings.sizes[size].height}
            className={classnames(styles.shape, className)}
            viewBox="0 0 406 176"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="405.417"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 405.417 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="379.167"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 379.167 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="352.916"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 352.916 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="326.667"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 326.667 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="300.416"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 300.416 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="274.167"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 274.167 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="247.917"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 247.917 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="221.667"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 221.667 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="195.417"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 195.417 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="169.166"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 169.166 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="142.917"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 142.917 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="116.667"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 116.667 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="90.4165"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 90.4165 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="64.167"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 64.167 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="37.9163"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 37.9163 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="11.6667"
                y="175.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 11.6667 175.964)"
                fill="#F7F7F7"
            />
            <rect
                x="405.417"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 405.417 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="379.167"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 379.167 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="352.916"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 352.916 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="326.667"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 326.667 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="300.416"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 300.416 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="274.167"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 274.167 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="247.917"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 247.917 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="221.667"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 221.667 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="195.417"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 195.417 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="169.166"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 169.166 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="142.917"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 142.917 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="116.667"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 116.667 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="90.4165"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 90.4165 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="64.167"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 64.167 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="37.9163"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 37.9163 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="11.6667"
                y="152.63"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 11.6667 152.63)"
                fill="#F7F7F7"
            />
            <rect
                x="405.417"
                y="129.297"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 405.417 129.297)"
                fill="#F7F7F7"
            />
            <rect
                x="379.167"
                y="129.297"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 379.167 129.297)"
                fill="#F7F7F7"
            />
            <rect
                x="352.916"
                y="129.297"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 352.916 129.297)"
                fill="#F7F7F7"
            />
            <rect
                x="326.667"
                y="129.297"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 326.667 129.297)"
                fill="#F7F7F7"
            />
            <rect
                x="300.416"
                y="129.297"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 300.416 129.297)"
                fill="#F7F7F7"
            />
            <rect
                x="274.167"
                y="129.297"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 274.167 129.297)"
                fill="#F7F7F7"
            />
            <rect
                x="247.917"
                y="129.297"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 247.917 129.297)"
                fill="#F7F7F7"
            />
            <rect
                x="221.667"
                y="129.297"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 221.667 129.297)"
                fill="#F7F7F7"
            />
            <rect
                x="195.417"
                y="129.297"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 195.417 129.297)"
                fill="#F7F7F7"
            />
            <rect
                x="169.166"
                y="129.297"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 169.166 129.297)"
                fill="#F7F7F7"
            />
            <rect
                x="142.917"
                y="129.297"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 142.917 129.297)"
                fill="#F7F7F7"
            />
            <rect
                x="116.667"
                y="129.297"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 116.667 129.297)"
                fill="#F7F7F7"
            />
            <rect
                x="90.4165"
                y="129.297"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 90.4165 129.297)"
                fill="#F7F7F7"
            />
            <rect
                x="64.167"
                y="129.297"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 64.167 129.297)"
                fill="#F7F7F7"
            />
            <rect
                x="11.6667"
                y="129.297"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 11.6667 129.297)"
                fill="#F7F7F7"
            />
            <rect
                x="405.417"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 405.417 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="379.167"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 379.167 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="352.916"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 352.916 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="326.667"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 326.667 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="300.416"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 300.416 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="274.167"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 274.167 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="247.917"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 247.917 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="221.667"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 221.667 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="195.417"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 195.417 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="169.166"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 169.166 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="142.917"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 142.917 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="116.667"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 116.667 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="90.4165"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 90.4165 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="64.167"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 64.167 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="37.9163"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 37.9163 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="11.6667"
                y="105.964"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 11.6667 105.964)"
                fill="#F7F7F7"
            />
            <rect
                x="405.417"
                y="82.6309"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 405.417 82.6309)"
                fill="#F7F7F7"
            />
            <rect
                x="379.167"
                y="82.6309"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 379.167 82.6309)"
                fill="#F7F7F7"
            />
            <rect
                x="352.916"
                y="82.6309"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 352.916 82.6309)"
                fill="#F7F7F7"
            />
            <rect
                x="300.416"
                y="82.6309"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 300.416 82.6309)"
                fill="#F7F7F7"
            />
            <rect
                x="274.167"
                y="82.6309"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 274.167 82.6309)"
                fill="#F7F7F7"
            />
            <rect
                x="247.917"
                y="82.6309"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 247.917 82.6309)"
                fill="#F7F7F7"
            />
            <rect
                x="221.667"
                y="82.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 221.667 82.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="195.417"
                y="82.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 195.417 82.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="169.166"
                y="82.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 169.166 82.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="142.917"
                y="82.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 142.917 82.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="116.667"
                y="82.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 116.667 82.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="90.4165"
                y="82.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 90.4165 82.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="64.167"
                y="82.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 64.167 82.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="37.9163"
                y="82.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 37.9163 82.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="11.6667"
                y="82.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 11.6667 82.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="405.417"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 405.417 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="379.167"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 379.167 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="352.916"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 352.916 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="326.667"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 326.667 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="300.416"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 300.416 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="274.167"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 274.167 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="247.917"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 247.917 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="221.667"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 221.667 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="195.417"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 195.417 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="169.166"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 169.166 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="142.917"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 142.917 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="116.667"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 116.667 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="90.4165"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 90.4165 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="64.167"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 64.167 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="37.9163"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 37.9163 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="11.6667"
                y="59.2972"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 11.6667 59.2972)"
                fill="#F7F7F7"
            />
            <rect
                x="405.417"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 405.417 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="379.167"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 379.167 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="352.916"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 352.916 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="326.667"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 326.667 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="300.416"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 300.416 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="274.167"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 274.167 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="247.917"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 247.917 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="221.667"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 221.667 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="195.417"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 195.417 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="169.166"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 169.166 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="142.917"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 142.917 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="116.667"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 116.667 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="90.4165"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 90.4165 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="64.167"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 64.167 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="37.9163"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 37.9163 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="11.6667"
                y="35.9637"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 11.6667 35.9637)"
                fill="#F7F7F7"
            />
            <rect
                x="405.417"
                y="12.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 405.417 12.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="379.167"
                y="12.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 379.167 12.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="352.916"
                y="12.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 352.916 12.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="326.667"
                y="12.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 326.667 12.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="300.416"
                y="12.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 300.416 12.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="274.167"
                y="12.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 274.167 12.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="247.917"
                y="12.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 247.917 12.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="221.667"
                y="12.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 221.667 12.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="195.417"
                y="12.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 195.417 12.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="169.166"
                y="12.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 169.166 12.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="142.917"
                y="12.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 142.917 12.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="116.667"
                y="12.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 116.667 12.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="90.4165"
                y="12.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 90.4165 12.6307)"
                fill="#F7F7F7"
            />
            <rect
                x="26.25"
                y="0.963989"
                width="11.6667"
                height="11.6667"
                fill="#F7F7F7"
            />
            <rect
                x="37.9175"
                y="129.298"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 37.9175 129.298)"
                fill="#58A1FA"
            />
            <rect
                x="326.667"
                y="82.6313"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 326.667 82.6313)"
                fill="#FF594B"
            />
            <rect
                x="52.501"
                y="0.964478"
                width="11.6667"
                height="11.6667"
                fill="#4D6982"
            />
            <rect
                x="11.6667"
                y="12.6307"
                width="11.6667"
                height="11.6667"
                transform="rotate(-180 11.6667 12.6307)"
                fill="#F7F7F7"
            />
        </svg>
    );
};

export default BasicGrid;

import React, { useState } from 'react';
import Chevron from '../../SVGComponents/Chevron';
import Cross from '../../SVGComponents/Cross';
import styles from './IwAccordion.module.scss';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown/with-html';

function IwAccordion({
    className,
    dataGaId = 'wantToKnowMore__question',
    title,
    content,
    children,
    color,
    lineClass,
    onClickFn = false
}) {
    const [isActive, setActive] = useState(false);

    const onClick = () => {
        if (onClickFn && !isActive) {
            onClickFn();
        }
        setActive(!isActive);
    };

    const panelStyle = isActive ? styles.ActivePanel : '';
    const chevronStyle = isActive ? styles.ActiveCross : '';

    return (
        <div className={classnames(styles.IwAccordionWrapper, className)}>
            <button
                className={styles.IwAccordion}
                onClick={onClick}
                data-ga-id={dataGaId}>
                <h3>{title}</h3>
                <Cross className={`${styles.Cross} ${chevronStyle}`} />
            </button>
            <div
                className={`${styles.Panel} ${panelStyle}`}
                data-testid="Panel">
                <div
                    className={classnames(styles.Divider, lineClass)}
                    style={{ backgroundColor: color }}></div>
                {children || (
                    <ReactMarkdown source={content} escapeHtml={false} />
                )}
            </div>
        </div>
    );
}

export default IwAccordion;

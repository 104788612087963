export { default as WedgedArch } from './Arches/WedgedArch';
export { default as BasicArch } from './Arches/BasicArch';
export { default as LongArch } from './Arches/LongArch';
export { default as PeopleArch } from './Arches/PeopleArch';
export { default as ArchWithCircle } from './Arches/ArchWithCircle';
export { default as BubbleArch } from './Arches/BubbleArch';
export { default as ArchWithSquare } from './Arches/ArchWithSquare';
export { default as BasicDoughnut } from './Doughnuts/BasicDoughnut';
export { default as FatDoughnut } from './Doughnuts/FatDoughnut';
export { default as BasicCircle } from './Circles/BasicCircle';
export { default as BasicSquare } from './Squares/BasicSquare';
export { default as BasicGrid } from './Grids/BasicGrid';

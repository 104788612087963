import React from 'react';
import classnames from 'classnames';
import styles from '../shapes.module.scss';
import settings from '../settings';

const Settings = {
    sizes: {
        xs: {
            width: 24,
            height: 24
        },
        sm: {
            width: '262',
            height: '262'
        },
        md: {
            width: '351',
            height: '351'
        },
        lg: {
            width: '474',
            height: '474'
        }
    },
    ...settings
};

const FatDoughnut = ({ className, color = 'coral', size = 'md' }) => {
    return (
        <svg
            className={classnames(styles.shape, className)}
            width={Settings.sizes[size].width}
            height={Settings.sizes[size].height}
            viewBox="0 0 29 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.19578 14.8823C5.19578 9.74375 9.36142 5.5781 14.5 5.5781C19.6386 5.57811 23.8042 9.74375 23.8042 14.8823C23.8042 20.0209 19.6386 24.1865 14.5 24.1865C9.36142 24.1865 5.19578 20.0209 5.19578 14.8823Z"
                stroke={Settings.colors[color]}
                stroke-width="10.3916"
            />
        </svg>
    );
};

export default FatDoughnut;

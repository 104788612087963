import React, { useState, useEffect, useRef } from 'react';
import { formatMoney } from 'aphrodite-shared/util/helpers';
import styles from './Bar.module.scss';
import classnames from 'classnames';

const Bar = ({
    principal,
    interest,
    fee = 0,
    isDirty,
    year,
    prevYearTotal,
    showFee
}) => {
    const ref = useRef(null);
    const total = principal + interest + fee;

    const [principalPercentage, setPrincipal] = useState(0);
    const [interestPercentage, setInterest] = useState(0);
    const [feePercentage, setFeePercentage] = useState(0);

    const [loadingPrincipalAnimation, setLoadingPrincipalAnimation] = useState(
        styles.BarPrincipalDisappear
    );
    const [loadingInterestAnimation, setLoadingInterestAnimation] = useState(
        styles.BarInterestDisappear
    );
    const [loadingFeeAnimation, setLoadingFeeAnimation] = useState(
        styles.BarFeeDisappear
    );

    useEffect(() => {
        if (isDirty) {
            setLoadingPrincipalAnimation(styles.BarPrincipalDisappear);
            setLoadingInterestAnimation(styles.BarInterestDisappear);
            setLoadingFeeAnimation(styles.BarFeeDisappear);
        } else {
            setLoadingPrincipalAnimation(styles.BarInterestAppear);
            setLoadingInterestAnimation(styles.BarInterestAppear);
            setLoadingFeeAnimation(styles.BarFeeAppear);
        }

        const barPercentage = getBarPercentage(prevYearTotal, total);
        setPrincipal((principal / total) * barPercentage);
        setInterest((interest / total) * barPercentage);
        setFeePercentage((fee / total) * barPercentage);
    }, [isDirty]);

    const isPrincipalBelowThreshold = getIsBelowThreshold(principalPercentage);

    const isInterestBelowThreshold = getIsBelowThreshold(interestPercentage);

    const isFeeBelowThreshold = getIsBelowThreshold(feePercentage);

    return (
        <div className={classnames(styles.Bar)} data-testid="Bar">
            <div
                className={styles.BelowThresholdPricing}
                style={{
                    bottom: `${
                        showFee
                            ? principalPercentage +
                              interestPercentage +
                              feePercentage
                            : principalPercentage + interestPercentage
                    }%`
                }}>
                {showFee && fee > 0 && isFeeBelowThreshold && (
                    <p className={styles.BelowThresholdFee}>
                        {formatMoney(fee)}
                    </p>
                )}
                {isInterestBelowThreshold && (
                    <p className={styles.BelowThresholdInterest}>
                        {formatMoney(interest)}
                    </p>
                )}
                {isPrincipalBelowThreshold && (
                    <p className={styles.BelowThresholdPrincipal}>
                        {formatMoney(principal)}
                    </p>
                )}
            </div>
            {showFee && (
                <div
                    style={{
                        bottom: `${principalPercentage + interestPercentage}%`,
                        height: `${feePercentage}%`
                    }}
                    className={classnames(styles.Fee, loadingFeeAnimation)}>
                    {!isFeeBelowThreshold && (
                        <div className={styles.Money}>{formatMoney(fee)}</div>
                    )}
                </div>
            )}
            <div
                ref={ref}
                className={classnames(
                    showFee && fee > 0 && styles.interestBorder,
                    styles.Interest,
                    loadingInterestAnimation,
                    showFee && styles.rlsInterest
                )}
                style={{
                    height: `${interestPercentage}%`,
                    bottom: `${principalPercentage}%`
                }}>
                {' '}
                {!isInterestBelowThreshold && (
                    <div className={styles.Money}>{formatMoney(interest)}</div>
                )}
            </div>
            <div
                ref={ref}
                className={classnames(
                    styles.Principal,
                    loadingPrincipalAnimation,
                    showFee && styles.rlsPrinciple
                )}
                style={{
                    height: `${principalPercentage}%`
                }}>
                {' '}
                {!isPrincipalBelowThreshold && (
                    <div className={styles.Money}>{formatMoney(principal)}</div>
                )}
            </div>
            {interestPercentage === 0 ? (
                <div className={styles.NoInterestText}>
                    <p>There's no fixed schedule.</p>
                    <p className={styles.bold}>
                        Repay however you like in 12 months.
                    </p>
                </div>
            ) : (
                <p className={styles.YearText}>Year {year}</p>
            )}
        </div>
    );
};

const getIsBelowThreshold = (barValue) => {
    const isMobile = typeof window !== 'undefined' && window.innerWidth < 801;
    return isMobile ? barValue < 8 : barValue < 6;
};

const getBarPercentage = (prevYearTotal, total) => {
    if (prevYearTotal && prevYearTotal > 0) {
        return (total / prevYearTotal) * 100;
    }
    return 100;
};

export default Bar;

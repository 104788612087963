import React from 'react';

const ArrowSVG = ({ className, onClick }) => {
    return (
        <div className={className} onClick={onClick}>
            <svg
                width="18"
                height="9"
                viewBox="0 0 18 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M2 2L8.84713 6.64384L15.56 2"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default ArrowSVG;

import React from 'react';
import classnames from 'classnames';
import styles from '../shapes.module.scss';
import settings from '../settings';

const Settings = {
    sizes: {
        md: {
            height: '291',
            width: '420'
        }
    },
    ...settings
};

const ArchWithSquare = ({
    className,
    primaryColor = 'london75',
    secondaryColor = 'denim',
    size = 'md',
    rotate = 'left'
}) => {
    return (
        <svg
            className={classnames(styles.shape, className)}
            width={Settings.sizes[size].width}
            height={Settings.sizes[size].height}
            viewBox={`0 0 ${Settings.sizes[size].width} ${Settings.sizes[size].height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                stroke={Settings.colors[primaryColor]}
                d="M296.81 232.021C264.324 115.983 135.03 50.74 8.02344 86.2965"
                strokeWidth="4"
            />
            <path
                fill={Settings.colors[secondaryColor]}
                d="M60.937 99.6272L16.3139 110.098C14.7461 110.467 13.2411 109.618 12.9154 108.182L3.71837 67.4651C3.39273 66.0289 4.38071 64.594 5.94857 64.225L50.4251 53.7848C51.993 53.4158 53.5012 54.2685 53.8236 55.701L63.0249 96.4142C63.3885 97.8465 62.5048 99.2582 60.937 99.6272Z"
            />
        </svg>
    );
};

export default ArchWithSquare;

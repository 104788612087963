import React, { useState, useEffect } from 'react';
import LoadingBalls from './LoadingBalls/LoadingBalls';
import Bar from './Bar/Bar';
import EmptyBar from './Bar/EmptyBar';
import styles from './Bars.module.scss';

const Bars = ({ repaymentSchedule, showFee = false }) => {
    const [isEndBalls, setIsEndBalls] = useState(true);
    const [isEndBars, setIsEndBars] = useState(false);

    const yearlySummary =
        repaymentSchedule &&
        repaymentSchedule.repayment_schedule_summary.yearly_summary;

    useEffect(() => {
        if (isEndBalls) {
            setIsEndBars(false);
        }
    }, [isEndBalls]);

    useEffect(() => {
        if (isEndBars) {
            setIsEndBalls(false);
        }
    }, [isEndBars]);

    return (
        <div className={styles.Bars}>
            {isEndBalls && !isEndBars && (
                <div className={styles.BarsWrapper}>
                    {yearlySummary.map(
                        (
                            {
                                principal_repayment,
                                interest_repayment,
                                funding_fee_repayment
                            },
                            year
                        ) => {
                            if (
                                principal_repayment === 0 &&
                                interest_repayment === 0
                            ) {
                                return (
                                    <EmptyBar
                                        key={year}
                                        isDirty={repaymentSchedule.dirty}
                                        setIsEnd={setIsEndBars}
                                        year={1}
                                    />
                                );
                            }
                            const prevYearTotal =
                                year > 0 &&
                                yearlySummary[year - 1].principal_repayment +
                                    yearlySummary[year - 1].interest_repayment +
                                    yearlySummary[year - 1]
                                        .funding_fee_repayment;
                            return (
                                // This bar always ends up being the last bar
                                <Bar
                                    key={principal_repayment}
                                    prevYearTotal={prevYearTotal}
                                    principal={principal_repayment}
                                    interest={interest_repayment}
                                    fee={funding_fee_repayment}
                                    showFee={showFee}
                                    isDirty={repaymentSchedule.dirty}
                                    year={year + 1}
                                />
                            );
                        }
                    )}
                </div>
            )}
            {!isEndBalls && isEndBars && (
                <LoadingBalls
                    setIsEnd={setIsEndBalls}
                    isDirty={repaymentSchedule.dirty}
                />
            )}
        </div>
    );
};

export default Bars;

export const getExampleRepaymentScheduleSummaries = async (
    initialPrincipal,
    maxLoanDuration
) => {
    // 30 day rate is calculated using
    // 0.1269 * (30/365.25) = 0.01042299794
    // 12.69% being the highest interest rate anyone is likely to receive as of 18/1/21
    const res = await fetch(
        `/api/lending/edge/example_repayment_schedule_summaries/?30_day_rate=0.01042299794&initial_principal=${initialPrincipal}&min_loan_duration_months=${maxLoanDuration}&max_loan_duration_months=${maxLoanDuration}`
    );
    return res.json();
};

export const getMonthSummary = (months, summaries) => {
    return summaries.find((summary) => {
        return summary.loan_duration_months == months;
    });
};

export const DEFAULT_REPAYMENT_SCHEDULE_CBILS = {
    // For a loan of 100k
    loan_duration_months: '36',
    repayment_schedule_summary: {
        monthly_repayment: 4739,
        total_repayment: 113730,
        total_interest_repayment: 13730,
        yearly_summary: [
            { principal_repayment: 0, interest_repayment: 0 },
            {
                principal_repayment: 46852,
                interest_repayment: 10013
            },
            {
                principal_repayment: 53148,
                interest_repayment: 3717
            }
        ]
    },
    dirty: false
};

export const DEFAULT_REPAYMENT_SCHEDULE_RLS = {
    // For a loan of 500k
    loan_duration_months: '60',
    repayment_schedule_summary: {
        monthly_repayment: 11629.04,
        total_repayment: 697742.4,
        total_interest_repayment: 182742.57,
        total_funding_fee_repayment: 15000,
        yearly_summary: [
            {
                principal_repayment: 64019.83,
                interest_repayment: 60528.65,
                funding_fee_repayment: 15000
            },
            {
                principal_repayment: 89341.56,
                interest_repayment: 50206.92,
                funding_fee_repayment: 0
            },
            {
                principal_repayment: 101244.23,
                interest_repayment: 38304.25,
                funding_fee_repayment: 0
            },
            {
                principal_repayment: 114866.95,
                interest_repayment: 24681.53,
                funding_fee_repayment: 0
            },
            {
                principal_repayment: 130527.27,
                interest_repayment: 9021.21,
                funding_fee_repayment: 0
            }
        ]
    },
    dirty: false
};

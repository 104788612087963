import React, { useState, useEffect, useRef, useContext } from 'react';
import styles from './PhonePopup.module.scss';
import Popup from './Popup/Popup';
import IwContext from 'aphrodite-shared/IwContext/IwContext';

function PhonePopup({ helpText, text, phoneNumber }) {
    const node = useRef();
    const context = useContext(IwContext);

    const [isPopupOpen, setPopup] = useState(false);
    const onClick = () => {
        //setPopup(!isPopupOpen);
        context.openModal('contactHelp');
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleOutsideClick = (e) => {
        if (!node.current.contains(e.target)) {
            setPopup(false);
            return;
        }
    };

    return (
        <div ref={node} className={styles.Help}>
            {isPopupOpen && (
                <Popup
                    setIsOpen={setPopup}
                    text={text}
                    phoneNumber={phoneNumber}
                    phoneNumberGaId="calculator__phone"
                />
            )}

            <span
                className={styles.PhonePopup}
                onClick={onClick}
                data-ga-id="calculator__contact_modal">
                {helpText}
            </span>
        </div>
    );
}
export default PhonePopup;

import React from 'react';

const TickSVG = ({ className }) => {
    return (
        <svg
            className={className}
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 5.62553L5.6254 11.2509L14.5599 2.31646L12.2435 0.00012207L5.6254 6.61824L2.31634 3.30918L0 5.62553Z"
                fill="#009967"
            />
        </svg>
    );
};

export default TickSVG;

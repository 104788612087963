import React from 'react';

const Cross = ({ className, onClick }) => {
    return (
        <svg
            onClick={onClick}
            data-testid="Cross"
            className={className}
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.4493 9.62736C19.0587 9.23684 18.4256 9.23684 18.035 9.62736L14.4995 13.1629L10.964 9.62736C10.5735 9.23684 9.94029 9.23684 9.54976 9.62736C9.15924 10.0179 9.15924 10.651 9.54976 11.0416L13.0853 14.5771L9.54976 18.1126C9.15924 18.5032 9.15924 19.1363 9.54976 19.5269C9.94029 19.9174 10.5735 19.9174 10.964 19.5269L14.4995 15.9913L18.035 19.5269C18.4256 19.9174 19.0587 19.9174 19.4493 19.5269C19.8398 19.1363 19.8398 18.5032 19.4493 18.1126L15.9137 14.5771L19.4493 11.0416C19.8398 10.651 19.8398 10.0179 19.4493 9.62736Z"
                fill="#08172B"
            />
        </svg>
    );
};

export default Cross;

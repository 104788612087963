import React from 'react';
import classnames from 'classnames';
import styles from '../shapes.module.scss';
import settings from '../settings';

const Settings = {
    sizes: {
        sm: {
            height: '200',
            width: '210'
        },
        md: {
            height: '316',
            width: '214'
        },
        lg: {
            height: '414',
            width: '351'
        }
    },
    ...settings
};

const BasicArch = ({
    className,
    color = 'coral',
    size = 'md',
    rotate = 'left'
}) => {
    return (
        <svg
            className={classnames(styles.shape, className)}
            width={Settings.sizes[size].width}
            height={Settings.sizes[size].height}
            viewBox={`0 0 ${Settings.sizes[size].width} ${Settings.sizes[size].height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M353 1.96385C141.532 19.5032 1.99999 207.871 2 416.964"
                stroke={Settings.colors[color]}
                strokeWidth="2.95665"
                strokeMiterlimit="10"
                transform={
                    rotate === 'right'
                        ? `translate(${Settings.sizes[size].width}, 0) scale(-1, 1)`
                        : ''
                }
            />
        </svg>
    );
};

export default BasicArch;
